<template>
  <div class="app-container">
    <eHeader :query="query" :tableStatus="tableStatus" @toQuery="toQuery" @clear="clear" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="userName" label="客户">
        <template slot-scope="scope">
          <span>{{scope.row.userName}}</span>
          <div>{{scope.row.id}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号"/>
      <el-table-column label="客户类型" width="150">
        <template slot-scope="scope">
            <span v-if="scope.row.custType!=null"><br>{{scope.row.custType==3?'直客':'代理'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="belongName" label="上级">
        <template slot-scope="scope">
          <div>{{scope.row.belongName}}</div>
          <div>{{scope.row.belongId}}</div>
        </template>
      </el-table-column>
      <el-table-column label="客户经理编号">
        <template slot-scope="scope">
          <div>{{scope.row.managerName}}</div>
          <div>{{scope.row.managerId}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="auditStatus" label="审核状态">
        <template slot-scope="scope">
          <span>{{ parseCusAuditStatus(scope.row.auditStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.auditTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
          <check class="my-button" v-if="checkPermission(['ADMIN','CUST_ALL','CUST_VIEW'])" :data="scope.row" :sup_this="sup_this" :isCheck="false" :tableStatus="tableStatus"/>
          <check class="my-button" v-if="checkPermission(['ADMIN','CUST_ALL','CUST_AUDIT'])&&visCheck" :data="scope.row" :sup_this="sup_this" :isCheck="true" :tableStatus="tableStatus"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { parseType,parseCusAuditStatus } from '@/views/pt/ib/utils/index'
import eHeader from './header'
import check from '@/components/ib/merchantinfo/view'

export default {
  components: { eHeader,check },
  mixins: [initData],
  props:{
    tableStatus: {
      type: Number,
      required: true
    },
    visCheck: {
      type: Boolean,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delLoading: false,
      sup_this: this
    }
  },
  watch:{
    refresh( newData,oldData){
        this.init()
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseCusAuditStatus,
    parseTime,
    parseType,
    checkPermission,

    beforeInit() {
      this.url = '/crm/getCustInfoWithMTInfo'
      const sort = 'createTime,desc'
      this.params = {
        custTypeStr:'3',
        page: this.page,
        size: this.size,
        sort: sort,
      }
      const query = this.query;
      const auditStatus = query.auditStatus;
      const startTime = this.parseTime(query.startTime);
      const endTime = this.parseTime(query.endTime);
      const managerInfo = query.managerInfo;
      if(this.tableStatus=='-1'){
        if(auditStatus!=null && auditStatus!=''){
          this.params['auditStatus'] = auditStatus;
        }
      }
      else if(this.tableStatus=='5') {
        this.params['auditStatus'] = '4';
        this.params['identifyStatus'] = '1';
        this.params['addressStatus'] = '1';
        this.params['bankStatus'] = '1';
        this.params['handleCertIdentifyStatus'] = '1';
      }
      else {
        this.params['auditStatus'] = this.tableStatus
      }
      if (this.query.basicInfo!=null && this.query.basicInfo!='') {
        this.params['basicInfo'] = this.query.basicInfo;
      }

      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      return true
    },
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
