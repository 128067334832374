<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="所属板块" prop="sectorCode">
        <el-select v-model="form.sectorCode" placeholder="请选择所属板块">
          <el-option
            v-for="item in sectorOptions"
            :key="item.sectorCode"
            :label="item.sectorName"
            :value="item.sectorCode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="form.userName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="用户全称">
        <el-input v-model="form.fullname" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" show-password style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input v-model="confirmpassword" show-password style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.phone" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="机构经理">
        <el-input v-model="form.managerId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="父机构" prop="parentMerchantId">
        <el-select v-model="form.parentMerchantId" placeholder="请选择父机构">
          <el-option
            v-for="item in merOptions"
            :key="item.id"
            :label="item.userName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构类型" prop="merchantType">
         <el-radio-group v-model="form.merchantType">
           <el-radio :label="item.key" :key="item.key" v-for="item in ibTypeOptions">{{item.display_name}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/ib/ibMerchant'
import { initData } from '@/api/data'
import { mapGetters } from 'vuex'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  created(){
    this.initOptions()
  },
  computed: {
    ...mapGetters([
      'ibTypeOptions'
    ])
  },
  data() {
    return {
      loading: false, dialog: false,confirmpassword:'',sectorOptions:null,merOptions:null,
      form: {
        id: '',
        sectorCode: '',
        userName: '',
        fullname: '',
        password: '',
        phone: '',
        email: '',
        managerId: '',
        parentMerchantId: '',
        merchantType: 1
      },
      rules:{
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符(10个汉字)', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { required: 'email', message: '请输入正确的邮箱', trigger: 'blur' }
        ],
        merchantType: [
          { required: true, message: '机构类型不能为空', trigger: 'blur' }
        ]
      }

    }
  },
  methods: {
    initOptions(){
      var url='crm/tBusinessSector';
      var params = '';
      initData(url,params).then(res=>{
        this.sectorOptions=res.content
      }).catch(err => {
        console.log(err.response.data.message)
      })
      url='api/tMerchant'
      initData(url,params).then(res=>{
        this.merOptions=res.content
      }).catch(err => {
        console.log(err.response.data.message)
      })
    },
    cancel() {
      this.resetForm()
    },
    doSubmit() {
       this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        }else{
          return false
        }
      })
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.initOptions()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        sectorCode: '',
        userName: '',
        fullname: '',
        password: '',
        phone: '',
        email: '',
        managerId: '',
        parentMerchantId: '',
        merchantType: 1
      }
      this.confirmpassword=''
    }
  }
}
</script>

<style scoped>

</style>
