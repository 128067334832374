<template>
  <div class="button-div">
    <el-button v-if="!isCheck&&checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_SELECT'])" size="mini" type="success" @click="to(1)">查看</el-button>
    <el-button v-if="!isCheck&&checkPermission(['ADMIN','CUST_SERVER_ALL','CUST_SERVER_SELECT'])" size="mini" type="success" @click="dialogFormVisible = true">客服查看</el-button>
    <el-button v-if="isCheck&&checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_AUDIT','CUST_ALL','CUST_AUDIT'])" size="mini" type="danger" @click="to(0)">审核</el-button>
    <detail
      :tableStatus="tableStatus"
      ref="detail"
      :sup_this="sup_this"
      :isCheck="isCheck"
      :data="viewData"
      :isShowSubLink="isShowSubLink"
    />

    <el-dialog title="权限验证" :visible.sync="dialogFormVisible" append-to-body width="30%">
      <div class="send-li">
        <li>
          <span class="code-font">手机验证码</span>
        </li>
        <li>
          <el-input placeholder="请输入手机验证码" v-model="smsCode"></el-input>
        </li>
        <li>
          <span v-show="show" @click="getCode" class="count">获取验证码</span>
          <span v-show="!show" class="count">{{count}} s</span>
        </li>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCode">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import detail from "./detail";
import checkPermission from '@/utils/permission'
import { sendPhoneVerCode, authPhoneVerCode } from "@/api/system/user";
import { initData } from '@/api/data';
export default {
  components: { detail },
  props: {
    tableStatus:{},
    isCheck: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      smsCode: "",
      show: true,
      count: "",
      timer: null,
      id: this.data.id,
      viewData:{},
      // 审核是不显示子代理链接,查看时显示全部子代理链接
      isShowSubLink: true,
    };
  },
  methods: {
    checkPermission,
    to(isShowSubLink) {

      this.isShowSubLink = isShowSubLink == 1 ? true : false;
      const _this = this.$refs.detail;
      var op = "";
      if (this.isCheck) {
        op = "审核";
      } else {
        op = "查看";
      }
      _this.title = op;

      const url = 'crm/tCustInfo';
      const params = { id:this.data.id,page: 0, size: 10, flag:'false'}
      initData(url, params).then(res => {
        this.viewData = res.content[0];
        _this.dialog = true;
      })
      /*
      *     this.viewData.bankBackImg,
            this.viewData.bankFrontImg,
            this.viewData.oldOrgImg,
            this.viewData.oldFundImg,
            this.viewData.oldDepositImg,
            this.viewData.oldClientImg,
            this.viewData.oldTradeImg
      * */
    },
    getCode() {
      let params = { id: this.id };
      sendPhoneVerCode(params)
        .then(res => {
          this.$notify({
            title: res.message,
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {});
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    submitCode() {
      this.dialogFormVisible = false;
      let params = {
        id: this.id,
        verCode: this.smsCode
      };
      authPhoneVerCode(params)
        .then(res => {
          // if(res.status==200){
            this.to();
          // }else{
          //   this.$notify({
          //   title: res.message,
          //   type: "error",
          //   duration: 2500
          // });
          // }
        })
        .catch(err => {});
    }
  }
};
</script>

<style scoped>
.button-div {
  display: inline-block;
  margin-right: 3px;
}
.code-font {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.send-li li {
  display: inline-block;
  margin-right: 20px;
}
.count {
  padding: 10px 30px;
  background-color:rgb(30,144,255);
  color: aliceblue;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor:pointer;
}
</style>
