<template>
  <div class="app-container">
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
     <el-tab-pane v-if="checkPermission(['ADMIN','CUST_ALL','CUST_SELECT'])" label="全部列表" name="first">
        <tableView :refresh="refresh" :tableStatus='-1' :visCheck='false'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','CUST_ALL','CUST_AUDIT_CUS'])" label="客服部审核" name="second">
        <tableView :refresh="refresh1" :tableStatus='1' :visCheck='true'/>
      </el-tab-pane>
      <el-tab-pane  v-if="checkPermission(['ADMIN','CUST_ALL','CUST_AUDIT_TRADE'])" label="交易部审核" name="third">
        <tableView :refresh="refresh2" :tableStatus='2' :visCheck='true'/>
      </el-tab-pane>
      <el-tab-pane  v-if="checkPermission(['ADMIN',,'CUST_ALL','CUST_AUDIT_IMG'])" label="图片证明重审" name="six">
        <tableView :refresh="refresh5" :tableStatus='5' :visCheck='true'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','CUST_ALL','CUST_SELECT'])" label="已审核" name="fourth">
        <tableView :refresh="refresh3" :tableStatus='4' :visCheck='false'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','CUST_ALL','CUST_SELECT'])" label="已拒绝" name="fifth">
        <tableView :refresh="refresh4" :tableStatus='3' :visCheck='false'/>
      </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import tableView from '@/components/customer/accountchk/table'
import checkPermission from '@/utils/permission'
export default {
  name:'accountchk',
  components: { tableView },
  data(){
    return {
      activeName:'first',
      refresh:false,
      refresh1:false,
      refresh2:false,
      refresh3:false,
      refresh4:false,
      refresh5:false
    }
  },
  methods:{
    checkPermission,
    handleClick(tab, event) {
        switch(tab.name){
          case 'first':
            this.refresh=!this.refresh
          break;
          case 'second':
            this.refresh1=!this.refresh1
          break;
          case 'third':
            this.refresh2=!this.refresh2
          break;
          case 'fourth':
            this.refresh3=!this.refresh3
          break;
          case 'fifth':
            this.refresh4=!this.refresh4
          break;
          case 'six':
            this.refresh5=!this.refresh5
          break;
        }

      }
  }
}
</script>

<style scoped>

</style>
